<template>
  <div>
    <div 
      v-motion
      :initial="{ opacity: 0, y: 100 }"
      :enter="{ opacity: 1, y: 0, scale: 1 }"
      :hovered="{ scale: 1.1 }"
      :delay="100"
      :duration="1000"
    >
      <img src="@/assets/LineeTratteggiate/Linea5.svg" alt="linea tratteggiata" class="fixed container-lines z-10">
      <div class="container font-Poppins flex flex-col md:flex-row items-center z-20 p-20 min-h-[82vh]">
        <div class="z-10 md:w-1/2">
          <h1 class="text-7xl font-bold text-site-refoody-yellow [text-shadow:_10px_8px_14px_rgb(0_0_0_/_60%)]">
            SIAMO REFOODY
          </h1>
          <p class="text-white [text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] mt-6">
            ReFoody è nato tra le aule universitarie, dove un gruppo di studenti motivati e appassionati di sostenibilità ha deciso di unire le forze per fare la differenza. Ognuno di noi, con esperienze lavorative e conoscenze complementari, ha portato competenze uniche al progetto. Uniti da una visione comune e da un forte legame di amicizia, abbiamo trasformato un'idea semplice in una missione concreta: ridurre gli sprechi alimentari e offrire ai supermercati un modo sostenibile per valorizzare i prodotti in scadenza.
          </p>
        </div>
        <img src="@/assets/FotoTeam/fotogruppo.jpg" alt="Foto del team" class="w-full m-5 z-20 md:w-1/2 shadow-lg rounded-xl">
      </div>
    </div>

    <div class="container2 px-5 md:px-20 pb-10 md:pb-20 flex flex-col gap-10">
      <h2 
        v-motion
        :initial="{ opacity: 0, y:0}"
        :visible="{ opacity: 1, y:0, scale: 1 }"
        :delay="50"
        :duration="600"
        class="text-4xl md:text-6xl font-bold text-site-refoody-yellow [text-shadow:_10px_8px_14px_rgb(0_0_0_/_60%)]"
      >
        Membri
      </h2>
      <memberCard 
        :name="'Edoardo Mingozzi'" 
        :role="'Amministratore'" 
        :img="imgEdo" 
        :imgdotted="img6" 
        :description="'Sono dottore in Economia e Management, con una grande passione per i sistemi di Supply Chain. Attualmente ricopro il ruolo di Amministratore, che conduco con forte spirito di guida, grazie al mio carattere estroverso ed inclusivo.'"
      ></memberCard> 
      <memberCard 
        :name="'Luca Ferar'" 
        :role="'CTO'" 
        :img="imgLucone" 
        :imgdotted="img7" 
        :description="'All’interno di ReFoody mi occupo dello sviluppo dell’applicazione e della manutenzione stessa. Dopo la Laurea in Ingegneria informatica, ho deciso di dedicarmi ad un progetto in cui credo e che rispecchia la mia visione a lungo termine della salvaguardia del pianeta.'"
      ></memberCard> 
      <memberCard 
        :name="'Andrea Casadei'" 
        :role="'Designer'" 
        :img="imgCasa" 
        :imgdotted="img8" 
        :description="'Amo l’arte ed il Design, e la esprimo all’interno di ReFoody. Oltre a studiare presso l’Accademia delle Belle Arti di Rimini, mi occupo di curare tutte le parti grafiche relative al progetto, e di gestire i profili social della nostra startup. All’interno di ReFoody sono libero di esprimere al massimo la mia creatività, che rispecchia a pieno la mia persona.'"
      ></memberCard> 
      <memberCard 
        :name="'Francesco Pazzaglia'" 
        :role="'Full Stack Developer'" 
        :img="imgChecco" 
        :imgdotted="img9" 
        :description="'Grazie alle mie pregresse esperienze come Full Stack Developer, sto seguendo con successo tutto lo sviluppo dell’applicativo lato Business e lato User. Credo molto nel progetto di ReFoody e sono un problem solver per eccellenza, soft skill che ho sviluppato durante il corso universitario di Ingegneria Informatica.'"
      ></memberCard> 
    </div>
    <div class="container2 px-5 md:px-20 pb-10 md:pb-20 flex flex-col gap-10">
      <h2 
        v-motion
        :initial="{ opacity: 0, y:0}"
        :visible="{ opacity: 1, y:0, scale: 1 }"
        :delay="50"
        :duration="600"
        class="text-4xl mt-10 md:text-6xl font-bold text-site-refoody-yellow [text-shadow:_10px_8px_14px_rgb(0_0_0_/_60%)] text-center"
      >
        Riconoscimenti
      </h2>
      <div class="awards-wrapper gap-3 p-4">
        <awards
          :title="'1° Premio sostenibilità Startup Day'"
          :description="'Premiati da Granarolo come Idea Imprenditoriale più sostenibile, durante lo Startup Day di Bologna 2024'"
          :img="bando1"
          :link="'https://youtu.be/fP3jJO2bF3o?si=dUFpR3rhvnnSDEZb'"
          class="inline-block"
        ></awards>
        <awards
          :title="'App contro lo spreco alimentare per l’acquisto di prodotti in scadenza scontati nei supermercati'"
          :description="''"
          :img="bando2"
          :link="'https://www.startcupemiliaromagna.it/2024/09/interviste-ai-finalisti-2024-refoody/'"
          class="inline-block"
        ></awards>
        <awards
          :title="'Finale Regionale Startcup-Ecosister'"
          :description="'Accesso alla finale regionale della competizione tra startup in Emilia Romagna'"
          :img="bando3"
          :link="'#'"
          class="inline-block"
        ></awards>
      </div>
    </div>
  </div>
</template>

<script>
  import memberCard from '@/components/member-card-component.vue';
  import awards from './awards-component.vue';
  import imgEdo from '@/assets/FotoTeam/edo.jpg';
  import imgLucone from '@/assets/FotoTeam/lucone.jpg';
  import imgChecco from '@/assets/FotoTeam/checco.jpg';
  import imgCasa from '@/assets/FotoTeam/casa.jpg';
  import img6 from '@/assets/LineeTratteggiate/Linea6.svg';
  import img7 from '@/assets/LineeTratteggiate/Linea7.svg';
  import img8 from '@/assets/LineeTratteggiate/Linea8.svg';
  import img9 from '@/assets/LineeTratteggiate/Linea9.svg';

  import bando1 from '@/assets/FotoTeam/PremiazioneStartupDay.png'
  import bando2 from '@/assets/FotoTeam/fotogruppo.jpg'
  import bando3 from '@/assets/FotoTeam/PitchFase.jpeg'


  export default {
    components: {
      memberCard,
      awards
    },
    data() {
      return {
        imgEdo,
        imgLucone,
        imgChecco,
        imgCasa,
        img6,
        img7,
        img8,
        img9,
        bando1,
        bando2,
        bando3
      }
    }
  }
</script>

<style scoped>
.container {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  position: relative;
}

.container2 {
  max-width: 1440px;
  margin: auto;
  padding: 0 10px;
}

.awards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}

.container-lines {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
    padding: 20px;
  }

  .awards-wrapper {
    flex-direction: row;
  }
}

h2 {
  text-align: center;
}

</style>