<template>
  <div>
    <div class="bg-site-refoody-yellow">
      <Navbar :pageSelected="'sostenibility'"></Navbar>
    </div>
    <div class="bg-site-refoody-yellow">
      <div class="relative overflow-hidden bg-cover"
           :style="{ backgroundImage: `url(${require('@/assets/LineeTratteggiate/Linea10.svg')})` }">
        <div class="container mx-auto py-20">
          <div class="flex flex-col md:flex-row justify-between items-center space-y-10 md:space-y-0">
            <div class="w-full md:w-1/2 text-black text-center md:text-left md:pr-10">
              <p>La sostenibilità è al cuore di Refoody. Con la nostra piattaforma, non solo aiutiamo i supermercati a
                ridurre lo spreco alimentare, ma trasformiamo anche i prodotti in scadenza in opportunità di vendita.
                Promuoviamo un modello di consumo responsabile, contribuendo alla riduzione dell'impatto ambientale e
                sostenendo un'economia circolare. Scegliendo Refoody, i supermercati possono adottare pratiche più
                ecologiche, migliorare i propri valori ESG e rispettare le normative ambientali future, impattando anche
                sugli obiettivi SDGs dell’Agenda 2030.</p>
            </div>
            <div class="w-full md:w-1/2">
              <img src="@/assets/sostenibilità/Pianeta.jpg" alt="Hands holding Earth"
                   class="rounded-lg w-5/6 h-auto shadow-lg"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Main Content Area 2 -->
    <div class="container mt-24">
      <div class="bg-site-secondary py-12">
        <h1 class="text-center text-4xl font-bold text-site-refoody-blue mb-12">Su cosa impatta ReFoody?</h1>
        <div class="flex flex-col md:flex-row justify-center items-center space-y-12 md:space-y-0 md:space-x-6 text-center text-white">
          <div class="md:w-1/3">
            <h1 class="font-bold text-xl mb-4">Consumo e produzione responsabili</h1>
            <p>ReFoody velocizza la vendita di prodotti in scadenza.</p>
            <!-- Hide image on larger screens, show only on mobile -->
            <img src="../assets/sostenibilità/SDG-icon-IT-RGB-12.jpg" alt="SDG 12" class="h-48 rounded-lg shadow-lg mt-6 mx-auto md:hidden"/>
          </div>
          <div class="md:w-1/3">
            <h1 class="font-bold text-xl mb-4">Lotta contro il cambiamento climatico</h1>
            <p>ReFoody evita l’impatto ambientale generato dallo smaltimento di cibo scaduto.</p>
            <!-- Hide image on larger screens, show only on mobile -->
            <img src="../assets/sostenibilità/SDG-icon-IT-RGB-13.jpg" alt="SDG 13" class="h-48 rounded-lg shadow-lg mt-6 mx-auto md:hidden"/>
          </div>
          <div class="md:w-1/3">
            <h1 class="font-bold text-xl mb-4">Partnership per gli obiettivi</h1>
            <p>ReFoody mette in contatto due attori fondamentali alla riduzione dello spreco: distributori e consumatori.</p>
            <!-- Hide image on larger screens, show only on mobile -->
            <img src="../assets/sostenibilità/SDG-icon-IT-RGB-17.jpg" alt="SDG 17" class="h-48 rounded-lg shadow-lg mt-6 mx-auto md:hidden"/>
          </div>
        </div>
      </div>
    </div>

    <!-- Images only for desktop view -->
    <div class="container w-full flex justify-between hidden md:flex">
      <img src="../assets/sostenibilità/SDG-icon-IT-RGB-12.jpg" alt="SDG 12" class="h-48 rounded-lg shadow-lg mt-6 mx-auto"/>
      <img src="../assets/sostenibilità/SDG-icon-IT-RGB-13.jpg" alt="SDG 13" class="h-48 rounded-lg shadow-lg mt-6 mx-auto"/>
      <img src="../assets/sostenibilità/SDG-icon-IT-RGB-17.jpg" alt="SDG 17" class="h-48 rounded-lg shadow-lg mt-6 mx-auto"/>
    </div>

    <div class="w-full mt-12">
      <img src="@/assets/sostenibilità/frase.svg" alt="Frase" class="w-full h-auto"/>
    </div>

    <!-- Main Content Area 4-->
    <div class="w-full bg-site-secondary mt-24 py-10">
      <div class="flex flex-col items-start px-8">
        <h1 class="text-4xl text-white w-full md:w-1/2 mb-8">Come si misura l’impatto di ReFoody e del supermercato sullo spreco?</h1>
      </div>
      <div class="flex flex-col md:flex-row justify-between items-center p-4 space-y-10 md:space-y-0 md:space-x-4">
        <div class="text-white">
          <p>
            La piattaforma terrà traccia del contributo e delle vendite generate da ciascun PdV. Attraverso una Dashboard il direttore del supermercato potrà consultare il quantitativo totale di prodotti alimentari non buttati, il risparmio in termini di CO2 prodotte dallo smaltimento ed altri indici legati agli ESGs.
            Infine il supermercato potrà apporre il nostro logo in store, per mostrare al pubblico il suo impegno contro lo spreco alimentare, dimostrato dalla collaborazione con ReFoody!
          </p>
        </div>
        <div>
          <img src="@/assets/sostenibilità/bag.png" alt="Bag"
               class="w-full h-auto rounded-lg shadow-lg"/>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/footer-component.vue';
import Navbar from "@/components/navbar-component.vue";

export default {
  name: 'SostenibilityView',
  components: {
    Footer,
    Navbar,
  },
}
</script>

<style>
.container {
  max-width: 1440px;
  margin: auto;
}
</style>