<template>
    <div 
      v-motion
      :initial="{ opacity: 0, y: 100 }"
      :visible-once="{ opacity: 0.8, y: 0, scale: 1 }"
      :hovered="{ opacity: 1, scale: 1.02 }"
      :delay="50"
      :duration="400"
      class="w-5/6 md:w-96 text-white flex flex-col p-4 backdrop-blur-sm shadow-xl rounded-xl relative overflow-hidden [text-shadow:_10px_8px_14px_rgb(0_0_0_/_60%)]"
    >
      <img :src="img" alt="card image" class="w-full shadow-lg rounded-t-xl h-60 object-cover transition-all duration-1000 hover:object-contain">
      
      <div class="p-4">
        <h2 class="font-bold text-2xl text-site-refoody-yellow">{{ title }}</h2>
        <p class="text-lg mt-2">{{ description }}</p>
        <a :href="link" class="text-site-refoody-blue opacity-90 mt-4 inline-block">Leggi di più</a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      link: {
        type: String,
        required: true
      },
      img: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>

  </style>