import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import ContactsView from '@/views/ContactsView.vue'
import SostenibilityView from '@/views/SostenibilityView.vue'
import NotFoundView from '@/views/notFoundView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Refoody' }
  },
  {
    path: '/chi-siamo',
    name: 'aboutus',
    component: AboutView,
    meta: { title: 'About Us' }
  },
  {
    path: '/contatti',
    name: 'contacts',
    component: ContactsView,
    meta: { title: 'Contacts' }
  },
  {
    path: '/sostenibilita',
    name: 'sostenibility',
    component: SostenibilityView,
    meta: { title: 'Sostenibility' }
  },
  {
    path: '/:catchAll()',
    name: 'notFound',
    component: NotFoundView,
    meta: { title: 'Error not found' }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
