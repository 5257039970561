<template>
  <div class="flex flex-col min-h-screen bg-site-secondary">
    <Navbar :pageSelected="'aboutus'"></Navbar>
    <div class="flex-grow">
      <Aboutus></Aboutus>
    </div>
    <div class="border border-site-refoody-yellow"></div>
    <Footer></Footer>
  </div>
</template>
  
<script>
  import Footer from '@/components/footer-component.vue';
  import Navbar from '@/components/navbar-component.vue';
  import Aboutus from '@/components/aboutus-component.vue';

  
  export default{
    name: 'AboutView',
    components: {
      Footer,
      Navbar,
      Aboutus,
    },
  };
</script>
  