<template>
  <div class="font-Poppins bg-white">
    <div class="relative flex flex-col">
      <Navbar :pageSelected="'home'"/>
      <div class="absolute inset-0 w-full backdrop-blur-sm">
        <img :src="immondizia" alt="immondizia" class="object-cover h-full w-full">
      </div> 
      <div class="z-20 w-full text-left container">
        <h1 class="text-white text-4xl md:text-6xl [text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] mt-20 z-20 font-bold w-full md:w-3/4">Trasforma le Scadenze in OPPORTUNITÀ: Riduci gli Sprechi, Aumenta i Ricavi.</h1>
        <p class="text-white text-xl [text-shadow:_3px_3px_14px_rgb(0_0_0_/_60%)] mb-24 z-20 w-full md:w-3/4 my-2">ReFoody è la piattaforma marketplace innovativa che consente ai supermercati di aumentare la visibilità delle referenze in scadenza al fine di monetizzarli e aumentare la sostenibilità del punto vendita. Con ReFoody, puoi raggiungere nuovi clienti e massimizzare i ricavi, mentre contribuisci a un futuro più sostenibile.</p>
      </div>
    </div>

    <div class="bg-white py-16">
      <div
        v-motion
        :initial="{ opacity: 0, y: 100 }"
        :enter="{ opacity: 1, y: 0, scale: 1 }"
        :hovered="{ scale: 1.1 }"
        :delay="100"
        :duration="1000"
        >
        <img src="@/assets/LineeTratteggiate/Linea1.svg" alt="linea tratteggiata" class="fixed container-lines z-10">
        <div class="container font-Poppins flex flex-col md:flex-row items-center z-20 px-20">
          <div class="z-10 md:w-2/3">
            <p class="text-black text-4xl md:text-6xl font-bold [text-shadow:_3px_3px_14px_rgb(0_0_0_/_10%)] mt-6 p-5">
              Molti supermercati…
            </p>
            <p class="text-black [text-shadow:_3px_3px_14px_rgb(0_0_0_/_20%)] mt-6 bg-white p-5">
              ...generano annualmente 12-15 kg/m² di rifiuti alimentari e hanno una scarsa presenza online, con la necessità di aumentare il traffico sulle proprie app. Inoltre, devono adottare pratiche più sostenibili, obbligandosi a redigere un bilancio di sostenibilità per essere conformi alle future normative
            </p>
          </div>
          <img src="@/assets/secchio.jpeg" alt="Foto verdure" class="w-full m-5 z-20 md:w-1/2 shadow-lg rounded-xl object-scale-down">
        </div>
      </div>
    </div>

  <div     
    v-motion
    :initial="{ opacity: 0, y: 100 }"
    :visible-once="{ opacity: 1, y: 0}"
    :delay="50"
    :duration="400"
    class="grid grid-cols-3 grid-rows-2 gap-4 h-64 text-black [text-shadow:_3px_3px_14px_rgb(0_0_0_/_10%)] mb-20"
  >
  <div class="flex items-center justify-center h-full"></div>
    <div class="items-center justify-center h-full" 
      v-motion
      :initial="{ opacity: 0, y: 100 }"
      :enter="{ opacity: 1, y: 0, scale: 1 }"
      :hovered="{ scale: 1.1 }"
      :delay="100"
      :duration="1000">
      <img src="@/assets/misc/teamicona.png" alt="icona monetizzazione" class="size-24 z-20 m-auto p-2">
      <p class="text-md md:text-lg text-center"> Maggiore traffico online e offline</p>
    </div>
    <div class="flex items-center justify-center h-full"></div>
    <div class="items-center justify-center h-full" 
      v-motion
      :initial="{ opacity: 0, y: 100 }"
      :enter="{ opacity: 1, y: 0, scale: 1 }"
      :hovered="{ scale: 1.1 }"
      :delay="100"
      :duration="1000">
      <img src="@/assets/misc/manosoldi.png" alt="icona monetizzazione" class="size-24 z-20 m-auto p-2">
      <p class="text-md md:text-lg text-center"> Monetizzazione prodotti in scadenza</p>
    </div>
    <div class="flex items-center justify-center h-full"></div>
    <div class="items-center justify-center h-full" 
      v-motion
      :initial="{ opacity: 0, y: 100 }"
      :enter="{ opacity: 1, y: 0, scale: 1 }"
      :hovered="{ scale: 1.1 }"
      :delay="100"
      :duration="1000">
      <img src="@/assets/misc/sostenibilita.png" alt="icona monetizzazione" class="size-24 z-20 m-auto p-2">
      <p class="text-md md:text-lg text-center">Maggiore contributo alla sostenibilità</p>
    </div>
  </div>
    <div class="bg-site-refoody-yellow py-16 h-96"
      v-motion
      :initial="{ opacity: 0, y: 100 }"
      :visible-once="{ opacity: 1, y: 0 }"
      :delay="50"
      :duration="400"
    >
      <div class="container mx-auto text-center">
        <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div class="text-white p-4 [text-shadow:_3px_3px_14px_rgb(0_0_0_/_30%)]">
            <h3 class="text-3xl font-semibold p-3 ">Tutto in App!</h3>
            <p class="text-xl [text-shadow:_3px_3px_14px_rgb(0_0_0_/_50%)]">All'interno dell'app ReFoody il consumatore potrà visualizzare i supermercati aderenti e consultare i sacchetti offerti da ciascuno</p>
          </div>
          <div class="bg-black shadow-md rounded-3xl">
            <img src="@/assets/misc/MockApp_Loading.jpg" alt="icona monetizzazione" class=" z-20 m-auto p-2 rounded-3xl">
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
    <div class="white min-h-[80vh]"></div>
    <div class="bg-white py-6 z-50"
      v-motion
      :initial="{ opacity: 0, y: 100 }"
      :visible-once="{ opacity: 1, y: 0}"
      :delay="50"
      :duration="400"
      > 
      <div class="container mx-auto text-center h-1/2">
        <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div class="text-xl p-4">
            <img v-if="w > minWidth" src="@/assets/banconeblu.png" alt="addetto supermercato" class="p-3 h-72 m-auto">
            <img v-if="w < minWidth" src="@/assets/banconebluMobile.png" alt="addetto supermercato" class="p-3 h-72 m-auto">
            <h3 class="text-xl font-semibold m-1">Supermarket Journey</h3>
            <div class="grid grid-row-3">
              <div></div>
              <div class="m-auto text-left">
              <p>1. Scelta dei prodotti da vendere grazie a ReFoody</p>
              <p>2. Composizione dei sacchetti (es: Medium/Carne)</p>
              <p>3. Pubblicazione sulla piattaforma</p>
              <p>4. Consegna al cliente in store</p>
            </div>
              <div></div>
            </div>
          </div>
          <div class="bg-white p-4">
            <img src="@/assets/addettogiallo.png" alt="customer supermercato" class="p-3 h-72 m-auto">
            <h3 class="text-xl font-semibold m-1">Customer Journey</h3>
            <div class="grid grid-row-3">
                <div></div>
                <div class="m-auto text-left">
                  <p>1. Scelta del supermercato</p>
                  <p>2. Scelta del sacchetto (es: Carne)</p>
                  <p>3. Prenotazione</p>
                  <p>4. Ritiro al supermercato</p>
                </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-site-secondary">
      <Footer/>
    </div>
  </div>
</template>

<script>
import immondizia from '@/assets/backgrounds/sfondohome.jpg';
import Navbar from "@/components/navbar-component.vue";
import Footer from "@/components/footer-component.vue";

export default {
  name: "HomeView",
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    window.addEventListener('resize', () => this.w = window.innerWidth )
  },
  data() {
    return {
      w: window.innerWidth,
      minWidth: 768,
      immondizia,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 1440px;
}
</style>