<template>
  <div class="w-full bg-site-refoody-blue h-32"></div>
  <div class="w-full bg-white h-12 overflow-hidden relative">
    <div class="absolute whitespace-nowrap animate-scroll p-1">
      <span v-for="i in 25" :key="i" class="slogan mr-2 font-Poppins font-bold text-4xl">
        REFOODY&nbsp;&nbsp;&nbsp;&nbsp;RESPECT&nbsp;&nbsp;&nbsp;&nbsp;RETHINK&nbsp;&nbsp;&nbsp;&nbsp;REDUCE&nbsp;&nbsp;&nbsp;&nbsp;REVALUE&nbsp;&nbsp;&nbsp;&nbsp;RENEW
      </span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  display: inline-block;
  white-space: nowrap;
  animation: scroll 100s linear infinite;
}
</style>