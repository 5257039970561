<template>
  <div 
    v-motion
    :initial="{ opacity: 0, y: 100 }"
    :visible-once="{ opacity: 0.8, y: 0, scale: 1 }"
    :hovered="{ opacity: 1, scale: 1.05 }"
    :delay="50"
    :duration="400"
    class="w-full text-white flex flex-col md:flex-row p-4 backdrop-blur-sm shadow-xl rounded-xl relative overflow-hidden [text-shadow:_10px_8px_14px_rgb(0_0_0_/_60%)]"
  >
    <img :src="imgdotted" alt="linea tratteggiata" class="absolute inset-0 object-cover w-full h-full z-10">

    <div class="w-full md:w-1/4 mb-4 md:mb-0">
      <img :src="img" alt="foto profilo" class="w-full shadow-lg rounded-xl z-20">
    </div>

    <div class="w-full md:w-1/4 flex flex-col md:pl-4 gap-2">
      <p class="font-bold text-3xl md:text-5xl">{{ name }}</p>
      <p class="text-lg md:text-xl text-site-refoody-blue opacity-90">{{ role }}</p>
    </div>

    <div class="w-full md:w-2/4 md:pl-4 mt-4 md:mt-0">
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    imgdotted: {
      type: String,
      required: true
    }
  }
}
</script>