<template>
  <div class="relative min-h-screen bg-site-secondary">
    <Navbar :pageSelected="'contacts'" class="absolute top-0 w-full z-20"></Navbar>
    <Contacts></Contacts>
    <Banner></Banner>
    <Bottom></Bottom>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/footer-component.vue';
import Navbar from '@/components/navbar-component.vue';
import Contacts from '@/components/contacts-component.vue';
import Banner from '@/components/banner-contacts-component.vue';
import Bottom from '@/components/bottom-contacts-component.vue';

export default {
  name: 'AboutView',
  components: {
    Footer,
    Navbar,
    Contacts,
    Banner,
    Bottom
  }
};
</script>