<template>
  <div class="relative min-h-screen flex flex-col items-center justify-center">
    <div class="absolute inset-0 w-full h-full z-10">
      <img :src="immondizia" alt="immondizia" class="w-full h-full object-cover size-[110%]">
      <div class="absolute inset-0 bg-gradient-to-t from-site-refoody-blue mt-96"></div>
    </div>

    <div class="relative z-20 text-center mt-32 md:mt-80">
      <h1 class="text-5xl md:text-7xl mb-24 md:mb-0 text-site-refoody-yellow font-bold [text-shadow:_10px_8px_14px_rgb(0_0_0_/_60%)]">
        PARLIAMONE
      </h1>
    </div>

    <div class="relative z-20 container flex flex-col md:flex-row items-center justify-center md:space-x-4">
      <div
        @mouseenter="onHover('investitori')"
        @mouseleave="onHover(null)"
        :class="[
          'p-8 transition-all duration-500 flex flex-col items-center justify-center', // Aggiunta flex e justify-center
          hovered === 'investitori' && isDesktop ? 'md:flex-[3]' : 'md:flex-1',
          hovered === null && isDesktop ? 'md:flex-1' : 'md:flex-[0.5]',
          'flex-1 min-h-[30vh]'
        ]"
        class="bg-white rounded-lg shadow-lg text-center mb-4 md:mb-0 md:mr-2"
      >
        <h2 class="text-site-refoody-blue text-3xl font-bold">INVESTITORI</h2>
        <p class="mt-4 text-gray-600 text-center">
          {{ hovered === 'supermercati' ? getFirstFiveWords(investitoriText) : investitoriText }}
        </p>
        <button class="mt-8 bg-site-secondary text-white py-2 px-4 rounded-full" @click="sendEmail">Contattaci!</button>
      </div>

      <div
        @mouseenter="onHover('supermercati')"
        @mouseleave="onHover(null)"
        :class="[
          'p-8 transition-all duration-500 flex flex-col items-center justify-center', // Aggiunta flex e justify-center
          hovered === 'supermercati' && isDesktop ? 'md:flex-[3]' : 'md:flex-1',
          hovered === null && isDesktop ? 'md:flex-1' : 'md:flex-[0.5]',
          'flex-1 min-h-[30vh]'
        ]"
        class="bg-yellow-500 rounded-lg shadow-lg text-center md:ml-2"
      >
        <h2 class="text-white text-3xl font-bold">SUPERMERCATI</h2>
        <p class="mt-4 text-white text-center">
          {{ hovered === 'investitori' ? getFirstFiveWords(supermercatiText) : supermercatiText }}
        </p>
        <button class="mt-8 bg-site-secondary text-white py-2 px-4 rounded-full" @click="sendEmail">Contattaci!</button>
      </div>
    </div>
  </div>
</template>

<script>
import immondizia from '@/assets/backgrounds/immondizia.jpg';

export default {
  data() {
    return {
      immondizia,
      hovered: null,
      isDesktop: false,
      investitoriText: "Sei un Business Angel, un Fool oppure un VC? Unisciti a noi e investi in ReFoody, la piattaforma che trasformerà il modo in cui i supermercati gestiscono i prodotti in scadenza, riducendo gli sprechi e creando nuove opportunità di profitto sostenibile. Che tu sia un Business Angel, un Fool o un Venture Capitalist, il futuro della sostenibilità alimentare ti aspetta.",
      supermercatiText: "Vorresti adottare una soluzione sostenibile per monetizzare i prodotti in scadenza nel tuo PdV? Scopri come Refoody può aiutarti a trasformare le perdite in opportunità. Contattaci oggi per avere maggiori informazioni o aiutarci nello sviluppo e porta il tuo supermercato verso un futuro più green e redditizio."
    };
  },
  mounted() {
    this.isDesktop = window.innerWidth >= 768;
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    sendEmail() {
        const mailtoLink = `mailto:admin@refoody.it?subject=Opportunità Refoody`;
        window.location.href = mailtoLink;
    },
    onHover(panel) {
      if (this.isDesktop) {
        this.hovered = panel;
      }
    },
    handleResize() {
      this.isDesktop = window.innerWidth >= 768;
    },
    getFirstFiveWords(text) {
      return text.split(' ').slice(0, 5).join(' ') + (text.split(' ').length > 5 ? '...' : ''); // Aggiunge '...' se ci sono più di 5 parole
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1440px;
  margin: auto;
}
</style>