<template>
    <div class="flex flex-col h-screen justify-between bg-site-primary">
      <Navbar :pageSelected="'0'"></Navbar>
        <p>error 404</p>
        <p>Torna alla home</p>
      <Footer></Footer>
    </div>
  </template>
    
  <script>
  import Footer from '@/components/footer-component.vue';
  import Navbar from '@/components/navbar-component.vue';
  
  export default{
    name: 'notFoundView',
    components: {
      Footer,
      Navbar,
    },
  };
  </script>
    