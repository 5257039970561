<template>
  <div class="bg-site-refoody-yellow">
    <div class="min-h-[90vh] flex flex-col md:flex-row font-Poppins container mx-auto pt-40 md:pt-0">
      <!-- Left Column -->
      <div class="w-full md:w-1/2 flex flex-col justify-center items-center text-center md:text-left">
        <div class="gap-10 flex flex-col">
          <div class="flex flex-col gap-5">
            <p class="text-2xl">Contatto telefonico (Edoardo)</p>
            <p class="text-3xl md:text-4xl lg:text-5xl mb-4 font-bold">+39 339 622 4538</p>
          </div>
          <div class="flex flex-col gap-5">
            <p class="text-2xl">Contatto Mail</p>
            <p class="text-3xl md:text-4xl lg:text-5xl font-bold">admin@refoody.it</p>
          </div>
        </div>
      </div>

      <!-- Modified Divider -->
      <div class="bg-black h-1 w-full my-16 md:my-4 md:h-auto md:w-1 md:mx-10 rounded-xl"></div>

      <!-- Right Column -->
      <div class="w-full md:w-1/2 flex flex-col justify-center items-center text-center md:text-left">
        <div>
          <p class="text-2xl pb-2 font-bold">Restiamo in contatto</p>
          <p class="text-2xl pb-10 font-normal md:font-bold ">Lasciami la tua mail!</p>
          <div class="flex justify-center">
            <input type="email" v-model="emailsaved" placeholder="Inserisci la tua email"
              class="p-3 px-6 text-lg border border-site-refoody-blue rounded-l-lg rounded-r-none w-4/5 md:w-3/4">
            <button @click="salvamail" class="bg-site-refoody-blue text-white rounded-r-lg p-3">Iscriviti</button>
          </div>
          <div v-if="erroreForm"
            class="bg-site-error bg-opacity-60 border border-site-error text-xs p-3 rounded-xl m-2">
            <p v-if="erroreForm">{{ erroreForm }}</p>
          </div>
          <div v-if="successoForm" class="bg-green-500 border border-green-600 text-xs p-3 rounded-xl m-2">
            <p v-if="successoForm">{{ successoForm }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      emailsaved: '',
      risposta: '',
      erroreForm: '',
      successoForm: '',
    }
  },
  methods: {
    salvamail() {
      this.erroreForm = '';
      this.successoForm = '';
      axios.post('https://www.refoody.it:9443/mlist/insert', { email: this.emailsaved })
        .then(response => {
          this.risposta = response.data.result;
          if (response.data.error == '') {
            this.emailsaved = '';
            this.successoForm = 'Email aggiunta con successo!'
          } else {
            this.erroreForm = response.data.result;
          }
        }).catch(error => {
          console.error(error);
        });
    },
  }
}
</script>

<style>
.container {
  max-width: 1440px;
  margin: auto;
}
</style>