<template>
  <router-view/>
</template>
<script>
</script>

<style>
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgb(55, 55, 55);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(35, 35, 35);
  border-radius: 5px;
  border: 2px solid rgb(55, 55, 55);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(85, 85, 85);
}


html {
  scrollbar-width: thin; /* Larghezza della barra di scorrimento */
  scrollbar-color: rgb(35, 35, 35) rgb(85, 85, 85); /* Colori della thumb e della track */
}
</style>