<template>
  <nav class="text-white z-50">
    <!-- Top Navbar -->
    <div class="container mx-auto flex justify-between items-center py-4">
      <!-- Menu Toggle for Mobile -->
      <div class="flex-1">
        <button @click="toggleMenu" class="text-white focus:outline-none md:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
      </div>

      <div class="flex-1 flex justify-center">
        <img src="../assets/LogoReFoody.svg" alt="Logo" />
      </div>

      <div class="flex-1 flex justify-end">
        <a href="https://www.instagram.com/refoody_italia" target="_blank" rel="noopener noreferrer">
          <img src="../assets/instagram.png" alt="Instagram Logo" class="size-10 bg-white rounded-full p-0.5 m-auto drop-shadow-lg" />
        </a>
      </div>
    </div>

    <div
      :class="{
        'hidden': !menuOpen && !isLargeScreen,
        'block': menuOpen || isLargeScreen
      }"
      class="container mx-auto md:flex justify-center gap-28 py-3 mt-4"
    >
      <div class="md:flex md:gap-28">
        <button
          v-for="page in pages"
          :key="page.key"
          @click="nextpage(page.key)"
          :class="{ 'underline-active': page.key === pageSelected }"
          class="block transition-colors duration-200 relative [text-shadow:_4px_4px_14px_rgb(0_0_0_/_90%)] py-2 md:py-0 text-lg "
        >
          {{ page.value }}
          <span 
            :class="['underline-animation', { 
              'underline-animation-blue': pageSelected === 'sostenibility',
              'underline-active': page.key === pageSelected 
            }]">
          </span>
        </button>
      </div>
    </div>

   
  </nav>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  props: {
    pageSelected: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      pages: [
        { key: "home", value: "HOME" },
        { key: "aboutus", value: "CHI SIAMO" },
        { key: "sostenibility", value: "SOSTENIBILITÀ" },
        { key: "contacts", value: "CONTATTI" }
      ]
    };
  },

  setup() {
    const menuOpen = ref(false);
    const isLargeScreen = ref(false);
    const router = useRouter();

    function toggleMenu() {
      menuOpen.value = !menuOpen.value;
    }

    function nextpage(key) {
      router.push({ name: key });
      menuOpen.value = false; 
    }

    function checkScreenSize() {
      isLargeScreen.value = window.innerWidth >= 768;
      if (isLargeScreen.value) {
        menuOpen.value = false;
      }
    }

    onMounted(() => {
      checkScreenSize();
      window.addEventListener('resize', checkScreenSize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenSize);
    });

    return {
      menuOpen,
      isLargeScreen,
      toggleMenu,
      nextpage
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 1440px;
}

button {
  position: relative;
  padding-bottom: 5px;
}

.underline-animation {
  position: absolute;
  bottom: 0;
  left: -20%;
  width: 140%;
  height: 2px;
  background-color: #ffc219;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.underline-animation-blue {
  background-color: #00c6e9;
}

button:hover .underline-animation {
  transform: scaleX(1);
  transform-origin: bottom left;
}

button.underline-active .underline-animation {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>